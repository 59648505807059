<h1 mat-dialog-title>
  <span [innerHTML]="title"></span>
</h1>
<div mat-dialog-content>
  <p [innerHTML]="message"></p>
</div>
<div mat-dialog-actions>
  <ng-container *ngIf="this.type == this.DIALOG_TYPE.YES_NO">
    <button (click)="onDismiss()" class="action-button" mat-raised-button>No</button>
    <button (click)="onConfirm()" class="action-button" color="warn" mat-raised-button>Yes</button>
  </ng-container>
  <ng-container *ngIf="this.type == this.DIALOG_TYPE.YES_NO_CRITICAL">
    <button (click)="onDismiss()" class="action-button important" mat-raised-button>No</button>
    <button (click)="onConfirm()" class="action-button important" color="warn" mat-raised-button>Yes</button>
  </ng-container>
  <ng-container *ngIf="this.type == this.DIALOG_TYPE.OK">
    <button (click)="onDismiss()" class="action-button" color="warn" mat-raised-button>Ok</button>
  </ng-container>
</div>
