import {FrontOrganization} from '../models/entities/frontOrganization';

export const LIST_ORGS: Array<FrontOrganization> = [{
  id: '',
  name: 'WFP',
  icon: 'wfp-logo',
  background: '/assets/wfp-background.jpg',
  fullname: 'The World Food Programme',
  description: 'The World Food Programme is the food-assistance branch of the United Nations.'  //  It is the world's largest humanitarian organization focused on hunger and food security, and the largest provider of school meals.
},
  {
    id: '',
    name: 'WHO',
    icon: 'who-logo',
    background: '/assets/who-background.jpg',
    fullname: 'The World Health Organization',
    description: 'The World Health Organization is a specialized agency of the United Nations responsible for international public health.' //  The WHO Constitution states its main objective as "the attainment by all peoples of the highest possible level of health".
  }, {
    id: '',
    name: 'UNHAS',
    icon: 'unhas-logo',
    background: '/assets/unhas-background.jpg',
    fullname: 'The United Nations Humanitarian Air Service',
    description: 'It provides common air services, including light cargo transport for the wider humanitarian community to and from areas of crisis and intervention.'  //  In most countries requiring humanitarian assistance, surface travel is impeded by challenging security situations, long distances and poor road conditions.
  },{
    id: '',
    name: 'UNICEF',
    icon: 'unicef-logo',
    background: '/assets/unicef-background.jpg',
    fullname: 'United Nations International Children\'s Emergency Fund',
    description: 'Agency of the United Nations responsible for providing humanitarian and developmental aid to children worldwide'
  },{
    id: '',
    name: 'UNDSS',
    icon: 'undss-logo',
    background: '/assets/undss-background.jpg',
    fullname: 'United Nations Department of Safety and Security',
    description: 'The Department of Safety and Security is a department of the United Nations providing safety and security services for United Nations agencies and departments as part of the United Nations Safety Management System'
  }];

export const WATCH_MISSIONS_URL = '/missions/watch';
export const WATCH_TRACKER_URL = '/locations/watch';
export const LIST_TRACKER_URL = '/locations'

