/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
  selector: 'input[uppercaseDir], [uppercaseDir]'
})
export class UppercaseInputDirective {
  constructor(private elRef: ElementRef) {
  }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initalValue = this.elRef.nativeElement.value;
    this.elRef.nativeElement.value = ((this.elRef.nativeElement.value ? this.elRef.nativeElement.value : '') + '').toUpperCase();
    if (initalValue !== this.elRef.nativeElement.value) {
      event.stopPropagation();
    }
  }
}
