import {Deserializable} from './Deserializable';

export class LocationResponse implements Deserializable {
  id?: string;
  trackerId?: string;
  missionId?: string;
  organizationId?: string;
  vehicleId?: string;
  vehicleIdentifier?: string;
  timestamp?: string;
  longitude?: string;
  latitude?: string;
  operationType?: string;

  deserialize(input: any): this {
    Object.assign(this, input)
    return this;
  }
}
