/**
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */
import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {
  LIST_TRACKER_URL,
  WATCH_MISSIONS_URL,
  WATCH_TRACKER_URL
} from '../constants/RestConstants';

@Injectable()
export class AppConfigService {
  constructor() {
  }

  /**
   * Use to app template found in the env file
   */
  public isProduction(): boolean {
    return environment.production;
  }

  /**
   * Get the backend URL found in the env file
   */
  public getBackendURL(): string {
    return environment.backendURL;
  }

  /**
   * Get the backend service found in the env file
   */
  public getBackendService(): string {
    return environment.backendService;
  }

  public getVersion(): string {
    return environment.version;
  }

  public getBuildTimestamp(): string {
    return environment.timestampBuild;
  }

  public getWatchMissionsPath(): string {
    return WATCH_MISSIONS_URL;
  }
  /****************************************************************************/

  /**
   * Get the backend URL found in the env file
   */
  public getTrackerURL(): string {
    return environment.trackerURL;
  }

  public getWatchTrackerPath(): string {
    return WATCH_TRACKER_URL;
  }

  public getListTrackersPath(): string {
    return LIST_TRACKER_URL;
  }
}
