/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {ConfirmDialogComponent, ConfirmDialogModel, DIALOG_TYPE} from '../dialogs/confirm-dialog.component';

@Injectable()
export class DialogService {
  constructor(
    private matDialog: MatDialog
  ) {
  }

  public showConfirmDialog(title: string, message: string, isCritical?: boolean): Observable<any> {
    const dialogData = new ConfirmDialogModel(title, message, !isCritical ? DIALOG_TYPE.YES_NO : DIALOG_TYPE.YES_NO_CRITICAL);
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
      disableClose: false
    });
    return dialogRef.afterClosed();
  }

  public showDialog(title: string, message: string): Observable<any> {
    const dialogData = new ConfirmDialogModel(title, message, DIALOG_TYPE.OK);
    const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
      maxWidth: '400px',
      data: dialogData,
      disableClose: false
    });
    return dialogRef.afterClosed();
  }
}
