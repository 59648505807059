import {Deserializable} from "./Deserializable";

export class Mission implements Deserializable {
  id: string;
  name: string;
  description: string;
  operationType?: string;

  deserialize(input: any): this {
    Object.assign(this, input)
    return this;
  }
}
