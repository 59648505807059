/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {Injectable} from '@angular/core';
import * as Keycloak from 'keycloak-js';
import {KeycloakInitOptions, KeycloakInstance, KeycloakPromise, KeycloakTokenParsed} from 'keycloak-js';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {KeycloakTokenModel} from '../models/auth/keycloak-token.model';
import {JwtHelper} from './jwt.helper';

export const keycloakPromise = <TSuccess>(keycloakPromise: KeycloakPromise<TSuccess, any>) => new Promise<TSuccess>((resolve, reject) =>
  keycloakPromise
    .then((result: TSuccess) => resolve(result))
    .catch((e: any) => reject(e))
);

@Injectable({
  providedIn: 'root'
})
export class KeycloakService {
  private keycloakInstance: KeycloakInstance;
  public lastAccessToken: string;

  constructor(
    private translateService: TranslateService
  ) {
  }

  public init(): Promise<void> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      this.keycloakInstance = new Keycloak(<Keycloak.KeycloakConfig>environment.keycloakConfig);
      this.keycloakInstance.init(<KeycloakInitOptions>environment.keycloakInitOptions).then(() => {
        resolve();

      }).catch(() => {
        reject();

      });
      // When idle you could need detect token expired
      this.keycloakInstance.onTokenExpired = () => {
        // Token expired so renew it
        console.log('#Token expired#');
        this.renewToken(false);
      }
    });
  }

  public getKeycloakInstance(): KeycloakInstance {
    return this.keycloakInstance;
  }

  public getToken(): string | undefined {
    return this.keycloakInstance.token;
  }

  public getDecodedToken(): KeycloakTokenModel | null {
    if (this.keycloakInstance.token) {
      return JwtHelper.decodeKeycloakToken(this.keycloakInstance.token);

    } else {
      return null;

    }
  }

  public logout(): void {
    const options = {
      redirectUri: environment.keycloakConfig.redirectUri,
      realm: environment.keycloakConfig.realm,
      clientId: environment.keycloakConfig.clientId
    };
    this.keycloakInstance.logout(options);
  }

  public renewToken(force: boolean): Promise<boolean> {
    const currentIat: number = new Date().getTime() / 1000;
    // @ts-ignore
    const lastValue: number = this.keycloakInstance.tokenParsed.iat;
    const MINIMUM_DIFFERENCE: number = 1.5;
    if (currentIat <= lastValue + MINIMUM_DIFFERENCE) {
      console.debug('Token already requested, so your request is ignored');
      return Promise.resolve(false);
    }

    // @ts-ignore
    const lastToken: KeycloakTokenParsed = this.keycloakInstance.tokenParsed;
    const tokenExpired: boolean = this.isTokenExpired();
    if ((tokenExpired || force) && this.keycloakInstance.refreshToken) {
      if (tokenExpired) {
        console.log('#Token expired# We have a refreshToken so next is getting a new Token#');
      } else {
        console.log('#Renewing Token to get a new one...');
      }
      let staticThis: any = this;
      return new Promise((resolve, reject) => {
        const NO_VALIDITY: number = -1;
        this.keycloakInstance.updateToken(NO_VALIDITY).then(function () {
          let isTokenChanged: boolean = false;
          if (lastToken.iat != staticThis.keycloakInstance.tokenParsed.iat) {
            isTokenChanged = true;
          }
          resolve(isTokenChanged)
        }).catch(function () {
          reject()
        });
      });

    } else {
      if (tokenExpired) {
        console.log('#Token expired# We have NOT a refreshToken so next is go to Login (using clearToken)#');
      } else {
        console.log('#Renewing Token to get a new one (without refreshToken need to clearToken)...');
      }
      return new Promise((resolve, reject) => {
        resolve(false);
      })
    }
  }

  /* After renew token this will refresh the whole website */
  public renewTokenAndRefresh(): void {
    this.keycloakInstance.clearToken();
  }

  public isTokenExpired(): boolean {
    return this.keycloakInstance.isTokenExpired();
  }
}
