/**
 * My API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';

import {AddVehicleWithTrackerToOrganization} from '../model/addVehicleWithTrackerToOrganization';
import {AssignVehicleToMission} from '../model/assignVehicleToMission';
import {UpdateVehicleInformation} from '../model/updateVehicleInformation';
import {Vehicle} from '../model/vehicle';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class VehicleControllerService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = '';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addVehicleWithTrackerToOrganization(body: AddVehicleWithTrackerToOrganization, observe?: 'body', reportProgress?: boolean): Observable<Vehicle>;

  public addVehicleWithTrackerToOrganization(body: AddVehicleWithTrackerToOrganization, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Vehicle>>;

  public addVehicleWithTrackerToOrganization(body: AddVehicleWithTrackerToOrganization, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Vehicle>>;

  public addVehicleWithTrackerToOrganization(body: AddVehicleWithTrackerToOrganization, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling addVehicleWithTrackerToOrganization.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Vehicle>('post', `${this.basePath}/vehicles`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public assignVehicleToMission(body: AssignVehicleToMission, observe?: 'body', reportProgress?: boolean): Observable<Vehicle>;

  public assignVehicleToMission(body: AssignVehicleToMission, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Vehicle>>;

  public assignVehicleToMission(body: AssignVehicleToMission, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Vehicle>>;

  public assignVehicleToMission(body: AssignVehicleToMission, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling assignVehicleToMission.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Vehicle>('patch', `${this.basePath}/vehicles/assign`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteVehicleToOrganization(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public deleteVehicleToOrganization(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public deleteVehicleToOrganization(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public deleteVehicleToOrganization(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteVehicleToOrganization.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    /* const consumes: string[] = [
    ];*/

    return this.httpClient.request<any>('delete', `${this.basePath}/vehicles/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param organizationId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAvailableVehiclesByOrganization(organizationId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Vehicle>>;

  public getAvailableVehiclesByOrganization(organizationId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Vehicle>>>;

  public getAvailableVehiclesByOrganization(organizationId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Vehicle>>>;

  public getAvailableVehiclesByOrganization(organizationId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (organizationId === null || organizationId === undefined) {
      throw new Error('Required parameter organizationId was null or undefined when calling getAvailableVehiclesByOrganization.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    /* const consumes: string[] = [
    ];*/

    return this.httpClient.request<Array<Vehicle>>('get', `${this.basePath}/vehicles/${encodeURIComponent(String(organizationId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param vehicleId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public unAssignVehicleToMission(vehicleId: string, observe?: 'body', reportProgress?: boolean): Observable<Vehicle>;

  public unAssignVehicleToMission(vehicleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Vehicle>>;

  public unAssignVehicleToMission(vehicleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Vehicle>>;

  public unAssignVehicleToMission(vehicleId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (vehicleId === null || vehicleId === undefined) {
      throw new Error('Required parameter vehicleId was null or undefined when calling unAssignVehicleToMission.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    /* const consumes: string[] = [
    ];*/

    return this.httpClient.request<Vehicle>('delete', `${this.basePath}/vehicles/${encodeURIComponent(String(vehicleId))}/mission`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateVehicleInformation(body: UpdateVehicleInformation, observe?: 'body', reportProgress?: boolean): Observable<Vehicle>;

  public updateVehicleInformation(body: UpdateVehicleInformation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Vehicle>>;

  public updateVehicleInformation(body: UpdateVehicleInformation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Vehicle>>;

  public updateVehicleInformation(body: UpdateVehicleInformation, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateVehicleInformation.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Vehicle>('patch', `${this.basePath}/vehicles/update`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

}
