/**
 * My API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {HttpClient, HttpEvent, HttpHeaders, HttpResponse} from '@angular/common/http';

import {Observable} from 'rxjs';

import {AddNewOrganization} from '../model/addNewOrganization';
import {DetailedOrganization} from '../model/detailedOrganization';
import {Organization} from '../model/organization';
import {UpdateOrganizationInformation} from '../model/updateOrganizationInformation';

import {BASE_PATH} from '../variables';
import {Configuration} from '../configuration';


@Injectable()
export class OrganizationControllerService {

  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();
  protected basePath = '';

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addNewOrganization(body: AddNewOrganization, observe?: 'body', reportProgress?: boolean): Observable<Organization>;

  public addNewOrganization(body: AddNewOrganization, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Organization>>;

  public addNewOrganization(body: AddNewOrganization, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Organization>>;

  public addNewOrganization(body: AddNewOrganization, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling addNewOrganization.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Organization>('post', `${this.basePath}/organizations`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteOrganizationToApplication(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;

  public deleteOrganizationToApplication(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;

  public deleteOrganizationToApplication(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;

  public deleteOrganizationToApplication(id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deleteOrganizationToApplication.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    /* const consumes: string[] = [
    ];*/

    return this.httpClient.request<any>('delete', `${this.basePath}/organizations/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAllOrganizations(observe?: 'body', reportProgress?: boolean): Observable<Array<Organization>>;

  public getAllOrganizations(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Organization>>>;

  public getAllOrganizations(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Organization>>>;

  public getAllOrganizations(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    /* const consumes: string[] = [
    ];*/

    return this.httpClient.request<Array<Organization>>('get', `${this.basePath}/organizations`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param orgId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getDetailedOrganization(orgId: string, observe?: 'body', reportProgress?: boolean): Observable<DetailedOrganization>;

  public getDetailedOrganization(orgId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DetailedOrganization>>;

  public getDetailedOrganization(orgId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DetailedOrganization>>;

  public getDetailedOrganization(orgId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (orgId === null || orgId === undefined) {
      throw new Error('Required parameter orgId was null or undefined when calling getDetailedOrganization.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    /* const consumes: string[] = [
    ];*/

    return this.httpClient.request<DetailedOrganization>('get', `${this.basePath}/organizations/${encodeURIComponent(String(orgId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public updateOrganizationInformation(body: UpdateOrganizationInformation, observe?: 'body', reportProgress?: boolean): Observable<Organization>;

  public updateOrganizationInformation(body: UpdateOrganizationInformation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Organization>>;

  public updateOrganizationInformation(body: UpdateOrganizationInformation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Organization>>;

  public updateOrganizationInformation(body: UpdateOrganizationInformation, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined when calling updateOrganizationInformation.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    let httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Organization>('patch', `${this.basePath}/organizations`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

}
