/*
 * @license
 * Copyright Hitec Luxembourg. All Rights Reserved.
 */

import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export enum DIALOG_TYPE {
  YES_NO = 1,
  YES_NO_CRITICAL = 2,
  OK = 3
}

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  public readonly DIALOG_TYPE = DIALOG_TYPE;

  public title: string;
  public message: string = '<ul><li class="circle-checkmark"></li></ul>';
  public type: DIALOG_TYPE;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel, public changeDetectorRef: ChangeDetectorRef,
  ) {
    // Update view with given values
    this.title = data.title;
    this.message = data.message;
    this.type = data.type;
  }

  ngOnInit() {
  }

  public onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  public onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class ConfirmDialogModel {
  constructor(public title: string, public message: string, public type: DIALOG_TYPE) {
  }
}
