import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {Configuration} from './configuration';
import {HttpClient} from '@angular/common/http';


import {CollectorControllerService} from './api/collectorController.service';
import {MissionControllerService} from './api/missionController.service';
import {OrganizationControllerService} from './api/organizationController.service';
import {TrackerControllerService} from './api/trackerController.service';
import {VehicleControllerService} from './api/vehicleController.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [
    CollectorControllerService,
    MissionControllerService,
    OrganizationControllerService,
    TrackerControllerService,
    VehicleControllerService]
})
export class ApiModule {
  constructor(@Optional() @SkipSelf() parentModule: ApiModule,
              @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
        'See also https://github.com/angular/angular/issues/20575');
    }
  }

  // @ts-ignore
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders {
    return {
      ngModule: ApiModule,
      providers: [{provide: Configuration, useFactory: configurationFactory}]
    };
  }
}
